<template>
  <v-container class="pa-0 grey lighten-4 pt-0" style="max-width: 600px">
    <div v-if="allInOne">
      <PayOrder :order="order1" :onChange="onChangeOrder1" :corporation_PIC="corporation_PIC" />
    </div>
    <div v-else>
      <div v-if="!!order1.products.length">
        <div class="my-2 pa-2 orange darken-2 white--text">
          {{ startDay.format('最短 MM月DD日 に受取可能な商品') }}
        </div>
        <PayOrder :order="order1" :onChange="onChangeOrder1" />
      </div>

      <div v-if="!!order2.products.length">
        <div class="my-2 pa-2 orange darken-2 white--text">
          {{ startDay.add(1, 'd').format('最短 MM月DD日 に受取可能な商品') }}
        </div>
      </div>
      <PayOrder :order="order2" :onChange="onChangeOrder2" />
    </div>

    <div v-if="showSum">
      <v-card class="mb-4 pt-1 px-2 pb-2" elevation="0" v-if="!is_corporation">
        <div class="mt-2 mb-3">
          <span class="pl-2" style="border-left: solid 8px orange; display: block; height: 30px">
            <span class="pt-1 font-weight-bold" style="display: inline-block">お支払い方法</span>
          </span>
        </div>

        <v-radio-group v-model="pay_method" column class="mt-1">
          <v-radio label="PayPay" color="orange" value="0"></v-radio>
          <v-radio label="クレジットカード" color="orange" value="1"></v-radio>
        </v-radio-group>
      </v-card>

      <v-card class="mb-4 pt-1 px-2 pb-2" elevation="0">
        <div class="mt-2 mb-3">
          <span class="pl-2" style="border-left: solid 8px orange; display: block; height: 30px">
            <span class="pt-1 font-weight-bold" style="display: inline-block">注意事項</span>
          </span>
        </div>
        <v-card-text class="pt-0">
          ・商品欠品時は、通知にてご連絡いたします。欠品商品の代金は返金させていただきます
        </v-card-text>
      </v-card>
      <v-container>
        <v-row class="pb-0">
          <v-col cols="7" class="pb-1 pt-2">
            <h5>商品小計（税込み）：</h5>
          </v-col>

          <v-col cols="5" class="text-right pb-2 pt-2">
            <h5>¥{{ cart_sum }}</h5>
          </v-col>
        </v-row>

        <v-row class="text-caption pb-1">
          <v-col cols="7" class="pb-2 pt-2">
            <h5>配送料・手数料：</h5>
          </v-col>

          <v-col cols="5" class="text-right pb-2 pt-2">
            <h5>¥{{ shipping_fee }}</h5>
          </v-col>
        </v-row>

        <v-row v-if="!is_corporation" class="text-caption pb-1">
          <v-col cols="7" class="pb-2 pt-2">
            <h5>クーポン：</h5>
          </v-col>

          <v-col cols="5" class="text-right pb-2 pt-2">
            <v-bottom-sheet v-model="couponsSheet">
              <template v-slot:activator="{ on, attrs }">
                <v-btn rounded outlined small v-bind="attrs" v-on="on">利用</v-btn>
              </template>
              <v-list>
                <v-subheader class="black--text font-weight-bold">ご利用可能なクーポン</v-subheader>
                <v-list-item
                  v-for="coupon in coupons"
                  :key="coupon._id"
                  @click="selectedCoupons(coupon)"
                >
                  <v-list-item-title>
                    {{ !coupon.type ? `${coupon.amount * 100}% 割引` : `${coupon.amount}円 OFF` }}
                    ({{ coupon.description }})
                    <v-chip label x-small>
                      {{ coupon.reusable ? '期間内無制限' : '一回のみ' }}
                    </v-chip>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-bottom-sheet>
          </v-col>
        </v-row>

        <v-row v-if="selectedCoupon">
          <v-col cols="1" class="pb-2 pt-1">
            <v-icon @click="closeCoupons">mdi-close-circle</v-icon>
          </v-col>
          <v-col cols="6" class="pb-2 pt-2">
            <h5>
              {{
                !selectedCoupon.type
                  ? `${selectedCoupon.amount * 100}% 割引`
                  : `${selectedCoupon.amount}円 OFF`
              }}
            </h5>
          </v-col>
          <v-col cols="5" class="text-right pb-2 pt-2">
            <h5>
              {{
                !selectedCoupon.type
                  ? `-${selectedCoupon.amount * 100}%`
                  : `-¥${selectedCoupon.amount}`
              }}
            </h5>
          </v-col>
        </v-row>

        <v-divider class="my-3"></v-divider>

        <v-row class="py-2">
          <v-col cols="7" class="pb-2 pt-2">
            <h4>合計</h4>
          </v-col>
          <v-col cols="5" class="text-right pb-2 pt-2">
            <h4>¥{{ final_sum_coupon }}</h4>
          </v-col>
        </v-row>

        <!-- orange darken-2  -->
        <v-row class="pt-0 grey darken-4" @click="confirm">
          <v-col class="text-center white--text text-h6" cols="12">次に進む</v-col>
        </v-row>
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent max-width="320">
            <v-card>
              <v-card-title class="text-h5">法人向けサービスは一時停止中です🙇‍♂️</v-card-title>
              <v-card-text class="text-caption">
                <p>いつもご利用いただきありがとうございます！</p>
                <p>
                  現在、法人向けサービスは一時停止しております。サービスの質を向上させるためのメンテナンスを実施中です。ご不便をおかけし誠に申し訳ございませんが、ご理解のほど宜しくお願い申し上げます。
                </p>
                <p>
                  以下のメールアドレスまで、何かご質問等がございましたら、お気軽にお問い合わせください。
                </p>
                <p style="font-weight: bold; margin-left: 4px; margin-bottom: 15px">
                  info@recosele.com
                </p>
                <div>
                  再開の日付が決定いたしましたら、速やかにご連絡させていただきます。
                  今後ともご愛顧賜りますようお願い申し上げます。
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="dialog = false">戻る</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
        <v-row justify="center">
          <v-dialog v-model="confirmDialog" persistent max-width="320">
            <v-card>
              <template v-if="final_sum_coupon > 10000">
                <v-card-title class="text-h5">ご注文金額が一万円以上のお客様へ</v-card-title>
                <v-card-text class="text-caption">
                  <p>&#10024;ご注文いただきありがとうございます！&#10024;</p>
                  <p>
                    お買い物の量が多く、持ち帰るのが大変になる場合がございます。快適なお持ち帰りができるよう、一度ご確認をお願い致します。
                  </p>
                  <p>&#x1F449;確認済の方は、「支払いに進む」をクリックして進んでください。</p>
                  <div>どうぞよろしくお願いします！</div>
                </v-card-text>
              </template>
              <v-card-title class="text-h5">注文詳細</v-card-title>
              <v-card-text class="text-body-1">
                <div>
                  <div>
                    <h4>店舗</h4>
                    {{ order1.pickup_spot.spot_name }}
                  </div>
                  <div>
                    <h4>受け取り時間</h4>
                    {{ order1.pickup_datetime_label }}
                  </div>
                </div>
                <div v-if="order2">
                  <div>
                    <h4>店舗</h4>
                    {{ order2.pickup_spot.spot_name }}
                  </div>
                  <div>
                    <h4>受け取り時間</h4>
                    {{ order2.pickup_datetime_label }}
                  </div>
                </div>
                <div class="d-flex justify-space-between mt-4">
                  <h4>商品金額</h4>
                  <h4>¥{{ cart_sum }}</h4>
                </div>
                <div v-if="selectedCoupon" class="d-flex justify-space-between">
                  <h4>クーポン</h4>
                  <h4>
                    {{
                      !selectedCoupon.type
                        ? `${selectedCoupon.amount * 100}% 割引`
                        : `${selectedCoupon.amount}円 OFF`
                    }}
                  </h4>
                </div>
                <div class="d-flex justify-space-between">
                  <h4>支払方法</h4>
                  <h4>{{ ['PayPay', 'クレジットカード'][pay_method] }}</h4>
                </div>
                <div class="d-flex justify-space-between">
                  <h4>手数料</h4>
                  <h4>¥{{ shipping_fee }}</h4>
                </div>
                <div class="d-flex justify-space-between align-end mb-3">
                  <h4 class="font-weight-black red--text">ポイント(所持:{{ point_amount }}p)</h4>
                  <div style="width: 80px" class="d-flex align-end">
                    <span class="font-weight-black red--text">利用:</span>
                    <v-text-field
                      class="font-weight-black"
                      hide-details="true"
                      dense
                      v-model="pointInput"
                      ref="pointInput"
                      type="number"
                      min="0"
                      max="100"
                    >
                      <!-- <v-icon slot="prepend">mdi-minus</v-icon> -->
                    </v-text-field>
                  </div>
                </div>
                <div class="d-flex justify-space-between">
                  <h4>合計</h4>
                  <h4>¥{{ final_sum_coupon - point }}</h4>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="confirmDialog = false">
                  カートに戻る
                </v-btn>
                <v-btn color="green darken-1" text @click="checkout">支払いに進む</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </div>
  </v-container>
</template>

<script>
  import Methods from '@/api/method.js';
  import PayOrder from './components/PayOrder.vue';
  export default {
    name: 'Pay',
    components: {
      PayOrder,
    },
    data: () => ({
      confirmDialog: false,
      products: [],
      order1: null,
      order2: null,
      couponsSheet: false,
      coupons: [],
      selectedCoupon: null,
      pointSheet: false,
      point_amount: 0,
      point: 0,
      pay_method: '1',
      cart_sum: null,
      final_sum: null,
      shipping_fee: 0,
      final_sum_coupon: null,
      dialog: false,
      is_corporation: false,
      corporation_PIC: ['', '', '', '', '', '', '', ''],
      startDay: null,
      checkbox: false,
    }),
    computed: {
      allInOne() {
        return this.$store.state.cart.allInOne !== '2';
      },
      showSum: function () {
        if (this.$store.state.user.is_corporation) {
          return true;
        } else if (this.allInOne) {
          return this.order1.pickup_spot;
        } else {
          return this.order1.pickup_spot && this.order2.pickup_spot;
        }
      },
      pointInput: {
        get() {
          return this.point;
        },
        set(num) {
          let max = this.point_amount;
          if (this.point_amount > this.final_sum_coupon) {
            max = this.final_sum_coupon;
          }
          num = parseInt(num);
          if (Number.isNaN(num) || num < 0) {
            num = 0;
          } else if (num > max) {
            num = max;
          }
          this.$refs.pointInput.lazyValue = num;
          this.point = num;
        },
      },
    },
    created() {
      /* corporation account */
      if (this.$dayjs().get('hour') > 8) {
        this.startDay = this.$dayjs().add(1, 'day');
      } else {
        this.startDay = this.$dayjs();
      }
      if (this.$store.state.user.is_corporation) {
        this.shipping_fee = 680;
        this.is_corporation = true;
      }
      let cart = this.$store.state.cart.cart;
      this.products = cart;
      let original_amounts = [0, 0, 0];
      let lead_time = 1;
      for (let sku of this.products) {
        const sum = Math.floor(
          sku.sku_number *
            (sku.is_onsale ? sku.sku_sale_price : sku.sku_price) *
            (1 + sku.product_fields.tax),
        );
        original_amounts[0] += sum;
        lead_time = sku.lead_time;
        if (sku.lead_time === 1) {
          original_amounts[1] += sum;
        }
        if (sku.lead_time === 2) {
          original_amounts[2] += sum;
        }
      }
      if (this.allInOne) {
        this.order1 = {
          lead_time,
          products: cart,
          original_amount: original_amounts[0],
        };
      } else {
        this.order1 = {
          lead_time: 1,
          products: cart.filter((item) => item.lead_time === 1),
          original_amount: original_amounts[1],
        };
        this.order2 = {
          lead_time: 2,
          products: cart.filter((item) => item.lead_time === 2),
          original_amount: original_amounts[2],
        };
      }
      let today = new Date();
      this.today_date = today.getMonth() + 1 + '月' + today.getDate() + '日';
      let sum = 0;
      for (let sku of this.products) {
        sum += Math.floor(
          sku.sku_number *
            (sku.is_onsale ? sku.sku_sale_price : sku.sku_price) *
            (1 + sku.product_fields.tax),
        );
      }
      this.cart_sum = sum;
      sum += this.shipping_fee;
      this.final_sum = sum;
      this.final_sum_coupon = sum;
    },
    mounted() {
      this.fetchUser();
    },
    methods: {
      onChangeOrder1: function (v) {
        this.order1 = v;
      },
      onChangeOrder2: function (v) {
        this.order2 = v;
      },
      async fetchUser() {
        try {
          const { data } = await Methods.GetUser({
            id: this.$store.state.user.user_id,
          });
          this.coupons = data?.coupon_list?.filter(({ status }) => status === 0) || [];
          this.point_amount = data?.point_amount;
        } catch (error) {
          console.log(error);
        }
      },
      selectedCoupons(value) {
        this.couponsSheet = false;
        this.selectedCoupon = value;
        if (value.type === 0) {
          this.final_sum_coupon = Math.floor(this.final_sum * (1 - value.amount));
        }
        if (value.type === 1) {
          let temp = this.final_sum - value.amount;
          if (temp < 0) {
            this.final_sum_coupon = 50; //最低注文金額は50円
          } else {
            this.final_sum_coupon = temp;
          }
        }
      },
      closeCoupons() {
        this.selectedCoupon = null;
        this.final_sum_coupon = this.final_sum;
      },
      confirm: function () {
        if (this.is_corporation) {
          this.dialog = true;
        } else {
          this.confirmDialog = true;
        }
      },
      checkout: async function () {
        this.confirmDialog = false;
        this.loading = true;
        if (
          this.products.find((data) => data.product_id == '6496f2b457fbc248172abc26') &&
          this.selectedCoupon
        ) {
          window.alert(
            '大変申し訳ありませんが、お試しセットのご注文の際にはクーポンの利用ができません。',
          );
          return 0;
        }
        if (this.is_corporation) {
          try {
            let PIC = this.corporation_PIC;
            // console.log(PIC);
            let text = `法人様の注文が入りました。
              user_is:${this.$store.state.user.user_id}
              ${PIC[0]}
              ${PIC[1]}
              ${PIC[2]}
              ${PIC[3]}
              ${PIC[4]}
              ${PIC[5]}
              ${PIC[6]}
              ===============
              商品一覧：`;
            for (let product of this.products) {
              text += `${product.product_fields.title}
              product_id:${product.product_id}
              sku_id:${product.sku_id}
              sku_number:${product.sku_number}
              sku_price:${product.sku_price}
              sku_sale_price:${product.sku_sale_price}
              *****************`;
            }
            // console.log(text);
            // TODO: orders.products.sku_price = orders.products.sku_sale_price
            let obj = {
              text,
              user_id: this.$store.state.user.user_id,
              delivery_type: 0,
              status: 0,
              pay_method: 0,
              company_name: PIC[0],
              shipping_fee: this.shipping_fee,
              date: PIC[1],
              name: PIC[2],
              email: PIC[3],
              tel: PIC[4],
              note: PIC[5],
              request: PIC[6],
              // products: this.order1.products,
              products: this.order1.products.map((item) => ({
                ...item,
                sku_price: item?.sku_sale_price || item?.sku_price,
              })),
              original_amount: this.order1.original_amount,
            };
            console.log(obj);
            await Methods.payByCompany(obj);
            this.$store.commit('cart/setMyCart', []);
            this.$store.commit('cart/setAllInOne', '1');
            // await Methods.sendSlackMessageCorp(obj);
            // console.log('sent slack message');
            this.$router.push('/?paid=true');
          } catch (err) {
            console.log(err);
            window.alert(
              'エラー:ご注文を処理できませんでした。お手数をおかけしますが、レコセレの公式LINEにてお問い合わせください。',
            );
          }
          return 0;
        }
        const coupon_id = this.selectedCoupon ? this.selectedCoupon.coupon_id : null;
        // TODO: orders.products.sku_price = orders.products.sku_sale_price
        let pay_data = {
          delivery_type: 0, //0 means self-pickup
          status: 0, // 0 means waiting for payment
          userAgent: navigator.userAgent,
          point: this.point,
          orders: this.allInOne
            ? [
                {
                  ...this.order1,
                  products: this.order1.products.map((item) => ({
                    ...item,
                    sku_price: item?.sku_sale_price || item?.sku_price,
                  })),
                  coupon_id,
                  shipping_fee: this.shipping_fee,
                },
              ]
            : [
                {
                  ...this.order1,
                  products: this.order1.products.map((item) => ({
                    ...item,
                    sku_price: item?.sku_sale_price || item?.sku_price,
                  })),
                  coupon_id,
                  shipping_fee: this.shipping_fee,
                },
                {
                  ...this.order2,
                  products: this.order2.products.map((item) => ({
                    ...item,
                    sku_price: item?.sku_sale_price || item?.sku_price,
                  })),
                  coupon_id,
                  shipping_fee: this.shipping_fee,
                },
              ],
          payment_method: this.pay_method,
        };

        /* PayPayによる支払い */
        if (this.pay_method == '0') {
          try {
            let response = await Methods.PayPayCheckout(pay_data);
            // let responseOK = response && response.status <= 400;
            // if (responseOK) {
            // let res_obj = JSON.parse(response.data);
            /* if using mobile device, directly call PayPay App */
            if (/Mobi|Android/i.test(navigator.userAgent)) {
              // window.location.href(res_obj.data.deeplink);
              // this.$router.go(response.data.deeplink)
              // this.$router.push(response.data.deeplink)
              // window.location.href = response.data.deeplink;
              // this.$router.push('/?paid=true');
              this.$router.push('/pay/result/' + response.data.paymentId);
              this.$store.commit('cart/setMyCart', []);
            }
            // else {
            // }
            this.loading = false;
            // }
          } catch (error) {
            alert(
              'PayPay支払いリンクの取得に失敗しました。お手数をおかけしますが、公式LINEのお問い合わせにご連絡いただけますようお願いいたします🙇‍♀️',
            );
          }
        } else {
          // Create an instance of the Stripe object with your publishable API key
          // var stripe = window.Stripe("pk_test_51ItyEXH9Xzi9nOCshgn3QnJ8f2DMUuBekceBs2pT0vprMunvQJ19Ip1PNkFeDedagzZr0mvGC0nyp7KsDLox4XMw00YLoclOyy");
          // var stripe = window.Stripe(
          //   process.env.VUE_APP_STRIPE_KEY,
          //   // 'pk_test_51LzdhYIbGeN8kZPysxi89hIjPxqCHFCqvm0oZ8nK9ie7KvLdYqmFtJPrf0byMtWamujFcJen9iRXxhoMT8uI8dAK00MRTFhwdw',
          //   // 'pk_live_51LzdhYIbGeN8kZPyOV2QgsQbsykvZXe1eXq7nKbZ8rb87ELlNzy5cJrDGsX0nirGyXlGDLLtjSyfhsvoFoebNJxN00cb5gJcEf',
          // );
          try {
            let response = await Methods.payByStripe(pay_data);
            // let responseOK = response && response.status <= 400;
            // if (responseOK) {
            this.$router.push('/pay/result/' + response.data.paymentId);
            this.$store.commit('cart/setMyCart', []);
            // let redirect_response = stripe.redirectToCheckout({
            //   sessionId: data.id,
            // });
            // if (redirect_response.error) {
            //   alert(redirect_response.error.message);
            // }
            // }else {
            // }
          } catch (err) {
            alert(
              'クレジット支払いリンクの取得に失敗しました。お手数をおかけしますが、公式LINEのお問い合わせにご連絡いただけますようお願いいたします🙇‍♀️',
            );
            console.log(err);
          }
        }
      },
    },
  };
</script>
<style scoped>
  .v-text-field .v-input__control .v-input__slot {
    min-height: 10px !important;
    display: flex !important;
    align-items: center !important;
  }
  .v-input input {
    min-height: 10px !important;
  }
</style>
