<template>
  <v-container class="pa-0 grey lighten-4 pt-0" style="max-width: 600px">
    <v-card class="mb-4 pt-1 px-2 pb-2" elevation="0">
      <div class="mt-2 mb-3">
        <span class="pl-2" style="border-left: solid 8px orange; display: block; height: 30px">
          <span class="pt-1 font-weight-bold" style="display: inline-block">ご注文内容</span>
        </span>
      </div>

      <v-list v-for="product in order.products" :key="product.product_id">
        <v-card class="mx-auto" max-width="390">
          <v-list-item three-line>
            <v-list-item-avatar tile size="80" color="grey">
              <v-img max-height="80" max-width="80" :src="product.product_fields.thumbnail"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <div class="mb-1 text--secondary">
                {{ product.product_fields.title }}
              </div>
              <div>
                <span v-for="{ choice_name } in product.sku_fields" :key="choice_name">
                  <v-chip v-if="!!choice_name" class="mr-2" x-small label>
                    {{ choice_name }}
                  </v-chip>
                </span>
              </div>
              <div v-if="product.is_onsale" class="mt-1">
                <span class="font-weight-bold text-decoration-line-through">
                  ¥{{ product.sku_price }}
                </span>
                <span class="font-weight-bold">&nbsp;¥{{ product.sku_sale_price }}</span>
                <span class="text-caption text--disabled">
                  ¥{{ Math.floor(product.sku_sale_price * (1 + product.product_fields.tax)) }}(税込)
                </span>
              </div>
              <div v-else class="mt-1">
                <span class="font-weight-bold">¥{{ product.sku_price }}</span>
                <span class="text-caption text--disabled">
                  ¥{{ Math.floor(product.sku_price * (1 + product.product_fields.tax)) }}(税込)
                </span>
              </div>
              <v-row no-gutters justify="end" align="end">
                <div class="mx-2">数量：{{ product.sku_number }}</div>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-list>
    </v-card>

    <!-- for corporation -->
    <div v-if="is_corporation" style="background-color: white" class="px-4 py-4 mt-2 mb-4">
      <!-- 配送店舗（選択）-->
      <v-text-field v-model="corporation_PIC[0]" label="配送先拠点名"></v-text-field>
      <!-- 日時指定（入力） -->
      <v-text-field
        label="ご希望の配送日と配達時間"
        v-model="corporation_PIC[1]"
        placeholder="例）2023年11月22日 12:00-17:00"
      ></v-text-field>
      <!-- 担当者様（入力） -->
      <v-text-field label="ご担当者様の氏名" v-model="corporation_PIC[2]"></v-text-field>
      <v-text-field label="ご担当者様のメールアドレス" v-model="corporation_PIC[3]"></v-text-field>
      <v-text-field label="ご担当者様の電話番号" v-model="corporation_PIC[4]"></v-text-field>
      <v-text-field label="イベントの開催日時" v-model="corporation_PIC[5]"></v-text-field>
      <v-text-field label="ご意見・ご要望" v-model="corporation_PIC[6]"></v-text-field>
      <div style="font-size: 11px" class="red--text">
        <span class="mincho" style="font-size: 15px">※</span>
        ご注文後、見積書は通常24時間以内にご担当者様のメールアドレスに送信されます。
      </div>
    </div>

    <div v-else>
      <v-card class="mb-4 pt-1 px-2 pb-2" elevation="0">
        <div class="mt-2 mb-3">
          <span class="pl-2" style="border-left: solid 8px orange; display: block; height: 30px">
            <span class="pt-1 font-weight-bold" style="display: inline-block">受け取り店舗</span>
          </span>
        </div>

        <div v-if="pickup_spot" class="text-center mb-2">
          <div class="my-3 text-h6">{{ pickup_spot.spot_name }}</div>
          <!-- <div class="my-3 text-caption">{{ pickup_spot.guide_text }}</div> -->
          <!-- <div class="my-3 text-caption">{{ pickup_spot.address }}</div> -->
          <!-- <div class="my-3 text-caption">{{ Object.keys(pickup_spot) }}</div> -->
          <v-btn outlined @click="sheet = !sheet">変更</v-btn>
          <!-- <div>{{Object.keys(pickup_spot)}}</div> -->
        </div>

        <div v-else class="text-center">
          <v-btn outlined @click="sheet = !sheet">選択する</v-btn>
        </div>

        <v-bottom-sheet v-model="sheet" scrollable>
          <v-card height="90vh" elevations="0">
            <div class="text-right mr-5 mt-5">
              <div @click="sheet = !sheet">
                <v-icon>mdi-close</v-icon>
              </div>
            </div>

            <v-overlay :value="map_loading">
              <v-progress-circular
                :size="70"
                :width="7"
                color="orange"
                indeterminate
              ></v-progress-circular>
            </v-overlay>

            <v-container style="overflow: auto" class="pb-3" elevations="0">
              <!-- memo: overflow enables scrolling inside v-card -->
              <!-- memo: 50% dont work, but 50vh do. -->
              <SpotsComponent
                @selected="selectedSpot($event)"
                @loading="rendered($event)"
                mode="spots-pay"
                :leadTime="order.lead_time"
              ></SpotsComponent>
            </v-container>
          </v-card>
        </v-bottom-sheet>
      </v-card>

      <div v-if="pickup_spot">
        <v-card class="mb-4 pt-1 px-2 pb-2" elevation="0">
          <div class="mt-2 mb-3">
            <span class="pl-2" style="border-left: solid 8px orange; display: block; height: 30px">
              <span class="pt-1 font-weight-bold" style="display: inline-block">受け取り日時</span>
            </span>
          </div>
          <v-radio-group v-model="pickup_datetime">
            <v-radio
              v-for="date in pickup_dates"
              :key="date.value"
              color="orange darken-2"
              :value="date.value"
              :label="date.label"
              @change="selectDate(date)"
            ></v-radio>
          </v-radio-group>
          <v-card-text class="pa-0 pt-2">
            {{ $dayjs().format('※ 本日の日付はMM月DD日です') }}
          </v-card-text>
          <div style="font-size: 11px" class="red--text">
            <span class="mincho" style="font-size: 15px">※</span>
            朝８時までのご注文は最短当日16時以降にお受け取りいただけます。
          </div>
          <div style="font-size: 11px" class="red--text">
            <span class="mincho" style="font-size: 15px">※</span>
            お受け取りに日時に変更がある場合は、お手数をおかけしますが、前日までにLINE公式にてご連絡ください。
          </div>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
  import SpotsComponent from '@/components/PickupSpots.vue';
  export default {
    name: 'Pay',
    components: {
      SpotsComponent,
    },
    props: {
      order: Object,
      corporation_PIC: Array,
      onChange: Function,
    },
    data: () => ({
      sheet: false,
      loading: true,
      map_loading: true,

      products: [],
      cart_sum: null,
      final_sum: null,
      pickup_spot: null,

      pickup_dates: [],

      pickup_spot_id: null,
      pickup_datetime: null,
      pickup_datetime_start: null,
      pickup_datetime_end: null,

      is_corporation: false,
    }),

    created() {
      if (this.$store.state.user.is_corporation) {
        this.is_corporation = true;
      }
    },
    methods: {
      rendered(value) {
        this.map_loading = value;
      },
      selectDate({ value, start,label, end }) {
        this.onChange({
          ...this.order,
          pickup_datetime: value,
          pickup_datetime_label:label,
          pickup_datetime_start: start,
          pickup_datetime_end: end,
        });
      },
      selectedSpot(value) {
        this.pickup_spot = value.spot;
        this.sheet = false;

        // 跟据选择的spot初始化可选时间
        let newDates = [];
        let pickDayStart = this.$dayjs();
        if (pickDayStart.get('hour') > 8) {
          pickDayStart = this.$dayjs().add(this.order.lead_time - 1, 'day');
        }
        for (const { date, duration, count } of this.pickup_spot.next_30days_schedule) {
          const tempDay = this.$dayjs(date).add(8, 'hour');
          // 早于今天的日子pass
          if (!pickDayStart.isBefore(tempDay)) continue;
          // 店铺不开张日子pass
          if (!duration) continue;
          if (!duration[0]) continue;
          // 不是周356pass
          if (![3, 5, 6].includes(tempDay.day())) continue;

          if (count >= this.pickup_spot.max_people_per_day) continue;
          // 最早的可选时间
          const [start, end] = duration[0].split('-');
          newDates.push({
            label: tempDay.format(`YYYY-MM-DD (dd) \u00A0\u00A0 ${duration}`),
            value: tempDay.format(`YYYY-MM-DD`),
            start: tempDay.format(`YYYY-MM-DDT${start}+0900`),
            end: tempDay.format(`YYYY-MM-DDT${end}+0900`),
          });
          if (newDates.length === 5) break;
        }
        this.pickup_dates = newDates;

        // 可选时间的第一个时间作为初始值
        const defaultDate = newDates[0] || {};
        this.pickup_datetime = defaultDate.value;
        this.onChange({
          ...this.order,
          user_id: this.$store.state.user.user_id,
          pickup_spot: value.spot,
          pickup_spot_id: value.spot._id,
          pickup_datetime: defaultDate.value,
          pickup_datetime_label: defaultDate.label,
          pickup_datetime_start: defaultDate.start,
          pickup_datetime_end: defaultDate.end,
        });
      },
    },
  };
</script>

<style scoped>
  .v-text-field .v-input__control .v-input__slot {
    min-height: 10px !important;
    display: flex !important;
    align-items: center !important;
  }
  .v-input input {
    min-height: 10px !important;
  }
</style>
