<template>
  <v-container style="max-width: 100vw" class="pa-0 pt-1">
    <v-card class="mx-1 mt-1">
      <div ref="map" :style="mapStyle()"></div>
      <div id="pac-input-wap">
        <input id="pac-input" class="controls" type="search" placeholder="ここで検索" />
        <v-btn id="pac-input-clear" fab x-small>
          <v-icon dark>mdi-close-thick</v-icon>
        </v-btn>
      </div>
    </v-card>

    <div @touchstart="touchstart">
      <v-bottom-sheet v-model="sheet" inset>
        <v-sheet class="text-center">
          <v-btn class="mt-3" text color="error" @click="sheet = !sheet">close</v-btn>
          <div>
            <v-card elevation="2" class="mx-3 my-5">
              <v-card-text
                class="pb-0 text--primary text-h6 font-weight-bold"
                v-text="spot ? spot.spot_name : ''"
              ></v-card-text>
              <v-card-text class="pb-0 pt-1 text--caption">
                {{ spot ? spot.guide_text : '' }}
              </v-card-text>

              <v-card-text class="pb-0 font-weight-bold">
                <span v-if="this.mode_pay" class="text--primary">お受け取り日時</span>
                <span v-else class="text--primary">直近でお受け取り可能な日時</span>
              </v-card-text>

              <v-card-text class="pb-0 pt-1">
                <span class="text--primary">
                  {{ pickup_datetime }}
                </span>
              </v-card-text>

              <v-card-text>
                <span class="text--primary">
                  <strong>定休日：</strong>
                </span>
                {{ spot ? spot.close_info : '' }}
              </v-card-text>

              <v-card-actions v-if="!this.showSelectButton">
                <v-row>
                  <v-col cols="9"></v-col>
                  <v-col cols="3" class="pt-0">
                    <v-btn text color="primary" @click="linkTo(spot._id)">詳細</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
            <div class="px-3 pb-6">
              <div v-if="this.showSelectButton">
                <v-card-text>
                  <span class="text--primary">
                    <strong>受取可能日：</strong>
                  </span>
                  {{ takeDays }}
                </v-card-text>
                <div v-if="spot ? spot.status === 1 : false">
                  <v-checkbox
                    v-model="checkbox"
                    class="pb-2 font-weight-black spot-checkbox"
                    hide-details
                    dense
                    label="私はこちらの従業員で間違いないです。"
                  ></v-checkbox>
                </div>
                <div v-if="spot ? spot.status === 2 : false">
                  <v-checkbox
                    v-model="checkbox"
                    class="pb-2 font-weight-black spot-checkbox"
                    hide-details
                    dense
                    label="私はこちらの入居者で間違いないです。"
                  ></v-checkbox>
                </div>
                <div v-if="spot ? spot.status === 3 : false">
                  <v-checkbox
                    v-model="checkbox"
                    class="pb-2 font-weight-black spot-checkbox"
                    hide-details
                    dense
                    label="私はこちらの関係者で間違いないです。"
                  ></v-checkbox>
                </div>
                <div>
                  <v-btn
                    @click="selected_clicked"
                    block
                    color="primary"
                    large
                    class="mt-2 font-weight-bold"
                  >
                    ここで受け取る
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </div>
  </v-container>
</template>

<script>
  import Methods from '@/api/method.js';
  export default {
    name: 'SpotsComponent',
    props: ['mode', 'leadTime'], //passed from parent
    data: () => ({
      myLatLng: { lat: 35.7126775, lng: 139.7598003 },
      spots: null,
      spot: null, //store selected spot data

      sheet: null,
      mode_pay: null,
      showSelectButton: false,
      mapScale: '30vh',

      pickup_datetime: null,
      date: null,
      duration: null,
      addDay: 0,
      checkbox: false,
    }),
    computed: {
      takeDays: function () {
        let res = [];
        if (this.spot && this.spot.default_schedule) {
          // if (this.spot.default_schedule.mon) res.push('月');
          // if (this.spot.default_schedule.tue) res.push('火');
          if (this.spot.default_schedule.wed) res.push('水');
          // if (this.spot.default_schedule.thu) res.push('木');
          if (this.spot.default_schedule.fri) res.push('金');
          if (this.spot.default_schedule.sat) res.push('土');
          // if (this.spot.default_schedule.sun) res.push('日');
        }
        return res.join('・');
      },
    },
    async created() {
      if (this.mode == 'spots-page') {
        this.showSelectButton = false;
        this.mapScale = 'calc(100vh - 130px)';
        this.mode_pay = false;
      } else if (this.mode == 'spots-pay') {
        this.showSelectButton = true;
        this.mapScale = 'calc(100vh - 160px)';
        this.mode_pay = true;
        this.addDay = this.leadTime - 1;
      }
    },

    async mounted() {
      let response = await Methods.getPickupSpots({
        // ここにクエリパラメータを指定する
        params: {
          // ここにクエリパラメータを指定する
          is_archived: 0,
        },
      });
      let spots = response.data.pickup_spots;
      this.spots = spots;
      console.log(this.spots);

      let response_obj = await Methods.getSpot(spots[0]._id);
      this.spot = response_obj.data;

      let pickDayStart = this.$dayjs();

      if (pickDayStart.get('hour') > 8) {
        pickDayStart = this.$dayjs().add(this.addDay, 'day');
      }

      for (const { date, duration, count } of this.spot.next_30days_schedule) {
        const tempDay = this.$dayjs(date).add(8, 'hour');
        // 早于今天的日子pass
        if (!pickDayStart.isBefore(tempDay)) continue;
        // 店铺不开张日子pass
        if (!duration) continue;
        if (!duration[0]) continue;
        // 不是周356pass
        if (![3, 5, 6].includes(tempDay.day())) continue;
        if (count >= this.spot.max_people_per_day) continue;
        // 最早的可选时间
        this.pickup_datetime = tempDay.format(`YYYY-MM-DD (dd) \u00A0\u00A0 ${duration}`);
        this.duration = duration;
        this.date = date;
        break;
      }

      const script = document.createElement('script');

      //TODO: GET API KEY from myServer
      script.src =
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyD814J5yieVqFMSdB16TjIk9CCPenm4LzY&callback=initMap&libraries=places';
      script.async = true;
      document.head.appendChild(script);

      var self = this; //this is important

      window.initMap = () => {
        let current = self.myLatLng;
        self.initMap(self.myLatLng, self, spots, current);
      };
    },
    methods: {
      createCenterControl: (map, latlng) => {
        const controlButton = document.createElement('button');

        // Set CSS for the control.
        controlButton.style.backgroundColor = '#fff';
        controlButton.style.border = '2px solid #fff';
        controlButton.style.borderRadius = '3px';
        controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
        controlButton.style.cursor = 'pointer';
        controlButton.style.margin = '10px';
        controlButton.style.padding = '7px';
        controlButton.style.textAlign = 'center';
        controlButton.type = 'button';

        const controlButtonInnerHTML = document.createElement('div');
        controlButtonInnerHTML.style.backgroundImage =
          "url('https://recosele-img.s3.ap-northeast-1.amazonaws.com/icon/30265.png')";
        controlButtonInnerHTML.style.backgroundPosition = '0px 0px';
        controlButtonInnerHTML.style.backgroundSize = '21px 21px';
        controlButtonInnerHTML.style.width = '21px';
        controlButtonInnerHTML.style.height = '21px';
        controlButtonInnerHTML.style.margin = '2px auto';

        const controlButtonInnerTextHTML = document.createElement('div');
        controlButtonInnerTextHTML.innerText = '現在地';
        controlButtonInnerTextHTML.style.fontSize = '10px';

        controlButton.appendChild(controlButtonInnerHTML);
        controlButton.appendChild(controlButtonInnerTextHTML);

        // Setup the click event listeners: simply set the map to Chicago.
        controlButton.addEventListener('click', () => {
          if (navigator.geolocation) {
            controlButtonInnerHTML.style.backgroundImage =
              "url('https://recosele-img.s3.ap-northeast-1.amazonaws.com/icon/30266.png')";
            controlButtonInnerTextHTML.style.color = '#52a2fc';
            navigator.geolocation.getCurrentPosition(function success(position) {
              let current = { lat: position.coords.latitude, lng: position.coords.longitude };
              new window.google.maps.Marker({
                map,
                position: current,
                animation: window.google.maps.Animation.DROP,
                zIndex: 1,
                icon: {
                  fillColor: 'dodgerblue', //塗り潰し色
                  fillOpacity: 0.8, //塗り潰し透過率
                  path: window.google.maps.SymbolPath.CIRCLE, //円を指定
                  scale: 10, //円のサイズ
                  strokeColor: 'white', //枠の色
                  strokeWeight: 1.0, //枠の透過率
                },
              });
              map.setCenter(current);
              map.setZoom(12);
            });
          } else {
            map.setCenter(latlng);
            map.setZoom(12);
          }
        });

        return controlButton;
      },
      initMap: (latlng, self, spots, current) => {
        // let map_style1 = [
        //   {
        //     featureType: 'poi',
        //     elementType: 'all',
        //     stylers: [{ visibility: 'off' }],
        //   },
        //   {
        //     featureType: 'all',
        //     elementType: 'all',
        //     stylers: [{ saturation: -50 }, { lightness: 20 }, { gamma: 0.5 }],
        //   },
        // ];
        let map_style2 = [
          {
            elementType: 'geometry',
            stylers: [
              {
                color: '#f5f5f5',
              },
            ],
          },
          {
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off',
              },
            ],
          },
          {
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#616161',
              },
            ],
          },
          {
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#f5f5f5',
              },
            ],
          },
          {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#bdbdbd',
              },
            ],
          },
          {
            featureType: 'landscape',
            stylers: [
              {
                color: '#ebeaea',
              },
            ],
          },
          {
            featureType: 'poi',
            stylers: [
              {
                visibility: 'on',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
              {
                color: '#eeeeee',
              },
            ],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#757575',
              },
            ],
          },
          {
            featureType: 'poi.attraction',
            stylers: [
              {
                weight: 1.5,
              },
            ],
          },
          {
            featureType: 'poi.business',
            stylers: [
              {
                saturation: 5,
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
              {
                color: '#e5e5e5',
              },
            ],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [
              {
                color: '#ffffff',
              },
            ],
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#ebeaea',
              },
            ],
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#757575',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [
              {
                color: '#dadada',
              },
            ],
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#616161',
              },
            ],
          },
          {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e',
              },
            ],
          },
          {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [
              {
                weight: 1.5,
              },
            ],
          },
          {
            featureType: 'transit.line',
            elementType: 'geometry',
            stylers: [
              {
                color: '#e5e5e5',
              },
            ],
          },
          {
            featureType: 'transit.line',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#b0b0b0',
              },
            ],
          },
          {
            featureType: 'transit.station',
            stylers: [
              {
                visibility: 'on',
              },
            ],
          },
          {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [
              {
                color: '#eeeeee',
              },
            ],
          },
          {
            featureType: 'transit.station.rail',
            elementType: 'geometry.stroke',
            stylers: [
              {
                color: '#343232',
              },
              {
                saturation: 10,
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
              {
                color: '#c9c9c9',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#94b9f4',
              },
            ],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e',
              },
            ],
          },
        ];

        // console.log(map_style1);
        const map = new window.google.maps.Map(self.$refs.map, {
          center: latlng,
          zoom: 12,
          zoomControl: true,
          scaleControl: false,
          rotateControl: true,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          styles: map_style2,
        });

        // Create the search box and link it to the UI element.
        const inputWap = document.getElementById('pac-input-wap');
        const input = document.getElementById('pac-input');
        const inputClear = document.getElementById('pac-input-clear');
        inputClear.onclick = () => {
          input.value = '';
        };
        const searchBox = new window.google.maps.places.SearchBox(input);
        map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(inputWap);
        // Bias the SearchBox results towards current map's viewport.
        map.addListener('bounds_changed', () => {
          searchBox.setBounds(map.getBounds());
        });

        let markers = [];

        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener('places_changed', () => {
          const places = searchBox.getPlaces();

          if (places.length == 0) {
            return;
          }

          // Clear out the old markers.
          markers.forEach((marker) => {
            marker.setMap(null);
          });
          markers = [];

          // For each place, get the icon, name and location.
          const bounds = new window.google.maps.LatLngBounds();

          places.forEach((place) => {
            if (!place.geometry || !place.geometry.location) {
              console.log('Returned place contains no geometry');
              return;
            }

            const icon = {
              url: place.icon,
              size: new window.google.maps.Size(71, 71),
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(17, 34),
              scaledSize: new window.google.maps.Size(25, 25),
            };

            // Create a marker for each place.
            markers.push(
              new window.google.maps.Marker({
                map,
                icon,
                title: place.name,
                position: place.geometry.location,
                zIndex: 1
              }),
            );
            if (place.geometry.viewport) {
              // Only geocodes have viewport.
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          map.fitBounds(bounds);
        });

        /* 現在地のボタン */
        const centerControlDiv = document.createElement('div');
        const centerControl = self.createCenterControl(map, current);
        centerControlDiv.appendChild(centerControl);
        map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(centerControlDiv);

        let spotsMarkers = [];

        for (let [index, spot] of spots.entries()) {
          if ('coordinate' in spot) {
            // if (spot._id == spots[0]._id) {
            //   markers[index] = new window.google.maps.Marker({
            //     position: spot.coordinate,
            //     icon: {
            //       url: 'https://www.recosele.com/aws_assets/marker50-2.png',
            //       scaledSize: new window.google.maps.Size(35, 35),
            //       // origin: new window.google.maps.Point(0,0), // origin
            //       // anchor: new window.google.maps.Point(0, 0) // anchor
            //     },
            //     map,
            //   });
            // } else {
            spotsMarkers[index] = new window.google.maps.Marker({
              position: spot.coordinate,
              icon: {
                url: 'https://www.recosele.com/aws_assets/marker50.png',
                scaledSize: new window.google.maps.Size(30, 30),
              },
              map,
              zIndex: 10
            });
            // }

            window.google.maps.event.addListener(spotsMarkers[index], 'click', async function () {
              // map.setZoom(13);
              // console.log(marker.latLng.lat())

              map.setCenter(spotsMarkers[index].position);

              Object.keys(spotsMarkers).forEach(function (key) {
                if (key === 'local') return;
                spotsMarkers[key].setIcon({
                  url: 'https://www.recosele.com/aws_assets/marker50.png',
                  scaledSize: new window.google.maps.Size(30, 30),
                });
              });

              spotsMarkers[index].setIcon({
                url: 'https://www.recosele.com/aws_assets/marker50-1.png',
                scaledSize: new window.google.maps.Size(30, 30),
              });

              // console.log(markers[index].position.lng())

              /* search the spotID with marker position. */
              let clicked_spot = spots.find((spot) => {
                if ('coordinate' in spot) {
                  if (
                    spot.coordinate.lat == spotsMarkers[index].position.lat() &&
                    spot.coordinate.lng == spotsMarkers[index].position.lng()
                  ) {
                    return true;
                  }
                }
              });

              let response = await Methods.getSpot(clicked_spot._id);

              let pickDayStart = self.$dayjs();

              if (pickDayStart.get('hour') > 8) {
                pickDayStart = self.$dayjs().add(self.addDay, 'day');
              }
              for (const { date, duration, count } of response.data.next_30days_schedule) {
                const tempDay = self.$dayjs(date).add(8, 'hour');
                // 早于今天的日子pass
                if (!pickDayStart.isBefore(tempDay)) continue;
                // 店铺不开张日子pass
                if (!duration) continue;
                if (!duration[0]) continue;
                // 不是周356pass
                if (![3, 5, 6].includes(tempDay.day())) continue;

                if (count >= response.data.max_people_per_day) continue;
                // 最早的可选时间
                self.pickup_datetime = tempDay.format(`YYYY-MM-DD (dd) \u00A0\u00A0 ${duration}`);
                self.duration = duration;
                self.date = date;
                break;
              }
              self.spot = response.data;
              self.checkbox = false;
              self.sheet = true;
            });
          }
        }

        self.$emit('loading', false);
        //TODO: included multiple times ERROR

        //memo : place service + place ID is now a good solution. Because it overlay the shop icon, cant click to show the info window. So no much difference.
      },
      selected_clicked() {
        if (this.spot.status === 0 || this.checkbox) {
          this.$emit('selected', {
            spot: this.spot,
            pickup_datetime: this.pickup_datetime,
            duration: this.duration,
            date: this.date,
          });
        } else {
          window.alert(
            'こちらは従業員様または入居者様の方のみ受け取り可能です。確認チェックをお願いいたします',
          );
        }
      },
      linkTo: function (id) {
        this.$router.push('/spots/' + id);
      },
      mapStyle() {
        let text = 'height: ' + this.mapScale + '; width: 100%';
        // console.log(text)
        return text;
      },
      touchstart(event) {
        event.preventDefault(); //阻止默认事件（长按的时候出现复制）
        console.log('first');
      },
    },
    destroyed() {
      window.google = {};
    },
  };
</script>
<style>
  .spot-checkbox > div > div > .v-label {
    color: #ff5252 !important;
  }

  #description {
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
  }

  #infowindow-content .title {
    font-weight: bold;
  }

  #infowindow-content {
    display: none;
  }

  #map #infowindow-content {
    display: inline;
  }

  .pac-card {
    margin: 10px 10px 0 0;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    font-family: Roboto;
  }

  #pac-container {
    padding-bottom: 12px;
    margin-right: 12px;
  }

  .pac-controls {
    display: inline-block;
    padding: 5px 11px;
  }

  .pac-controls label {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
  }

  #pac-input-wap {
    display: inline-flex;
    align-items: center;
    padding: 10px 3vw;
    width: 100%;
  }

  #pac-input {
    padding: 8px 14px;
    margin-right: 3vw;
    width: 100%;
    max-width: 472px;
    box-shadow: 0px 8px 10px #808080;
    background-color: #fff;
    border-radius: 100px;
    outline: none;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 300;
    text-overflow: ellipsis;
  }

  #pac-input:focus {
    border-color: #4d90fe;
  }

  .pac-input-clear {
    font-size: xx-small !important;
  }

  #title {
    color: #fff;
    background-color: #4d90fe;
    font-size: 25px;
    font-weight: 500;
    padding: 6px 12px;
  }

  #target {
    width: 345px;
  }
</style>
